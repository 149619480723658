// src/supabase.js
import { createClient } from "@supabase/supabase-js";

// 環境変数からSupabase URLを取得、設定されていない場合はデフォルト値を使用
const supabaseUrl = process.env.VUE_APP_SUPABASE_URL
    || (process.env.NODE_ENV === 'production'
        ? "https://dmcuyobytkjckmjbykxi.supabase.co"
        : "http://localhost:54321");
const supabaseAnonKey = process.env.VUE_APP_SUPABASE_ANON_KEY;

const supabase = createClient(supabaseUrl, supabaseAnonKey);

export default supabase;
