<template>
  <div class="pb-2">
    <b-row class="mb-4 align-items-center mx-0">
      <b-col xs="12" md="6">
        <h1 class="mb-3 mb-md-0">他会選手対策</h1>
      </b-col>
      <b-col xs="12" md="6" class="text-md-right">
        <b-button variant="success" class="w-100 mb-3 mb-md-0" @click="showAddPlayerModal">
          <font-awesome-icon :icon="['fas', 'user-plus']" />
          選手追加
        </b-button>
      </b-col>
    </b-row>
    <b-row>
      <b-alert variant="info" show class="w-100">
        <p class="mb-0">
          <font-awesome-icon :icon="['fas', 'sack-dollar']" />
          大会や練習で当たるたびに、情報を蓄積していきましょう！
        </p>
      </b-alert>
    </b-row>

    <b-tabs content-class="mt-3">
      <b-tab title="選手別" active>
        <b-row class="mb-3">
          <b-col xs="12">
            <b-input-group>
              <b-form-input
                v-model="searchQuery"
                placeholder="姓、名、かるた会名で検索"
                @input="filterPlayers"
              ></b-form-input>
            </b-input-group>
          </b-col>
        </b-row>

        <b-card>
          <b-pagination
            v-model="currentPage"
            :total-rows="filteredPlayers.length"
            :per-page="perPage"
            align="center"
            class="mt-3"
          ></b-pagination>
          <div v-for="player in paginatedPlayers" :key="player.id" class="mb-3">
            <b-card>
              <b-card-title>
                <router-link :to="{ name: 'PlayerDetail', params: { id: player.id } }">
                  {{ player.last_name }}{{ player.first_name }}
                  <small>({{ player.comment_count }})</small>
                </router-link>
              </b-card-title>
              <b-card-text>
                <p class="mb-1">
                  <strong>かるた会:</strong>
                  {{ player.current_club.name }}
                </p>
                <p class="mb-1">
                  <strong>利き手:</strong>
                  {{
                    player.dominant_hand === "right"
                      ? "右"
                      : player.dominant_hand === "left"
                      ? "左"
                      : player.dominant_hand === "other"
                      ? "その他"
                      : "不明"
                  }}
                  <!-- player.hand_notesが空でない場合のみ表示 -->
                  <template v-if="player.hand_notes">
                    <span class="text-muted">（{{ player.hand_notes }}）</span>
                  </template>
                </p>
              </b-card-text>

              <b-card-text v-if="player.latest_comment">
                <strong>最新コメント:</strong>
                {{ truncateComment(player.latest_comment.content) }}
                <br />
                <small class="text-muted">
                  {{ formatDate(player.latest_comment.created_at) }} {{ player.latest_comment.poster_name }}
                </small>
              </b-card-text>
              <b-card-text v-else class="text-muted">コメントなし</b-card-text>
            </b-card>
          </div>

          <b-pagination
            v-model="currentPage"
            :total-rows="filteredPlayers.length"
            :per-page="perPage"
            align="center"
            class="mt-3"
          ></b-pagination>
        </b-card>
      </b-tab>
      <b-tab title="直近のコメント">
        <b-row class="mb-3">
          <b-col xs="12">
            <b-input-group>
              <b-form-input
                v-model="commentSearchQuery"
                placeholder="コメント内容で検索"
                @input="fetchLatestComments"
              ></b-form-input>
            </b-input-group>
          </b-col>
        </b-row>

        <b-card>
          <div v-if="latestComments.length === 0">
            <p class="text-muted">コメントが見つかりませんでした</p>
          </div>
          <div v-for="comment in latestComments" :key="comment.id" class="mb-3">
            <b-card>
              <b-card-title>
                <router-link :to="{ name: 'PlayerDetail', params: { id: comment.player.id } }">
                  {{ comment.player.last_name }}{{ comment.player.first_name }}
                </router-link>
              </b-card-title>
              <b-card-text style="white-space: pre-line">
                {{ comment.content }}
                <br />
                <small class="text-muted">{{ formatDate(comment.created_at) }} {{ comment.poster_name }}</small>
              </b-card-text>
            </b-card>
          </div>
        </b-card>
      </b-tab>
      <b-tab title="かるた会別">
        <b-list-group>
          <div v-for="club in sortedClubs" :key="club.id" class="club-group">
            <b-list-group-item
              class="d-flex justify-content-between align-items-center club-item"
              @click="toggleClubDetails(club.id)"
            >
              <div class="d-flex align-items-center">
                <font-awesome-icon :icon="expandedClubs[club.id] ? 'chevron-down' : 'chevron-right'" class="mr-2" />
                {{ club.name }}
              </div>
              <b-badge variant="primary" pill>{{ club.player_count }}</b-badge>
            </b-list-group-item>
            <b-collapse :id="`collapse-${club.id}`" v-model="expandedClubs[club.id]" class="player-list">
              <b-card class="border-0 player-card">
                <b-list-group>
                  <b-list-group-item
                    v-for="player in getSortedPlayers(club)"
                    :key="player.id"
                    class="d-flex justify-content-between align-items-center player-item"
                    @click.stop="viewPlayerDetail(player.id)"
                  >
                    {{ player.last_name }}{{ player.first_name }}
                    <b-badge variant="info" pill>{{ player.comment_count }}</b-badge>
                  </b-list-group-item>
                </b-list-group>
              </b-card>
            </b-collapse>
          </div>
        </b-list-group>
      </b-tab>
    </b-tabs>

    <!-- 選手追加・編集モーダル -->
    <b-modal
      v-model="isPlayerModalVisible"
      :title="editingPlayer ? '選手編集' : '選手追加'"
      size="lg"
      @hide="resetPlayerForm"
    >
      <b-form @submit.prevent="savePlayer">
        <b-row>
          <b-col md="6">
            <b-form-group label="姓:" label-for="last_name">
              <b-form-input
                id="last_name"
                v-model="playerForm.last_name"
                required
                @input="searchPlayers"
              ></b-form-input>
            </b-form-group>
            <b-form-group label="名:" label-for="first_name">
              <b-form-input
                id="first_name"
                v-model="playerForm.first_name"
                required
                @input="searchPlayers"
              ></b-form-input>
            </b-form-group>
            <b-form-group label="かるた会:" label-for="current_club_name">
              <b-form-input
                id="current_club_name"
                v-model="playerForm.current_club_name"
                list="club-suggestions"
                required
              ></b-form-input>
              <datalist id="club-suggestions">
                <option v-for="club in clubOptions" :key="club.value" :value="club.text"></option>
              </datalist>
              <b-form-text small muted>※正式名称を入力してください。例：東京大学かるた会</b-form-text>
            </b-form-group>
            <b-form-group label="利き手:" label-for="dominant_hand">
              <b-form-select id="dominant_hand" v-model="playerForm.dominant_hand" required>
                <option value="right">右</option>
                <option value="left">左</option>
                <option value="other">その他</option>
              </b-form-select>
            </b-form-group>
            <b-form-group
              v-if="playerForm.dominant_hand === 'other'"
              label="利き手に関する補足:"
              label-for="hand_notes"
            >
              <b-form-input id="hand_notes" v-model="playerForm.hand_notes"></b-form-input>
            </b-form-group>
            <hr />
          </b-col>
          <b-col md="6">
            <h5>
              <font-awesome-icon :icon="['fas', 'exclamation-triangle']" class="mr-2 text-warning" />
              登録済の選手
            </h5>
            <p class="existing-players-description">
              入力された姓名に部分一致する選手です。重複登録を避けるため、よくご確認ください。特に異体字・旧字に注意してください（高橋と髙橋、山崎と山﨑など）。
            </p>
            <div v-if="similarPlayers.length > 0" class="existing-players-list">
              <b-list-group>
                <b-list-group-item v-for="player in similarPlayers" :key="player.id">
                  <div class="d-flex justify-content-between align-items-center">
                    <div class="flex-grow-1">
                      <strong>{{ player.last_name }}{{ player.first_name }}</strong>
                      <br />
                      <small>{{ player.current_club.name }}</small>
                    </div>
                    <div class="ml-3 d-flex align-items-center">
                      <b-button size="sm" variant="outline-success" @click.stop="viewPlayerDetail(player.id)">
                        開く
                      </b-button>
                    </div>
                  </div>
                </b-list-group-item>
              </b-list-group>
            </div>
            <p v-else class="text-muted">該当なし</p>
          </b-col>
        </b-row>
      </b-form>
      <template #modal-footer="{ cancel }">
        <b-button variant="secondary" @click="cancel()">キャンセル</b-button>
        <b-button
          variant="success"
          :disabled="
            !playerForm.last_name ||
            !playerForm.first_name ||
            !playerForm.current_club_name ||
            !playerForm.dominant_hand
          "
          @click="savePlayer"
        >
          {{ editingPlayer ? "更新" : "登録" }}
        </b-button>
      </template>
    </b-modal>

    <!-- 選手削除確認モーダル -->
    <b-modal v-model="isDeletePlayerConfirmVisible" title="削除確認" @hide="resetDeletePlayerConfirm">
      <p>本当にこの選手を削除しますか？この操作は元に戻せません。</p>
      <b-form @submit.prevent="deletePlayer">
        <b-form-group label="パスワードを入力してください:" label-for="password">
          <b-form-input id="password" v-model="deletePlayerPassword" type="password" required></b-form-input>
        </b-form-group>
      </b-form>
      <template #modal-footer="{ cancel }">
        <b-button variant="secondary" @click="cancel()">キャンセル</b-button>
        <b-button variant="danger" @click="deletePlayer">削除</b-button>
      </template>
    </b-modal>
  </div>
</template>

<script>
import supabase from "@/supabase";
import dayjs from "dayjs";
import config from "../config/adminUsersEmail";
import { firebaseApp } from "../main.js";
import { getAuth } from "firebase/auth";

export default {
  name: "PlayerManagement",
  components: {},
  data() {
    return {
      players: [],
      clubs: [],
      clubOptions: [],
      latestComments: [],
      similarPlayers: [],
      searchQuery: "",
      commentSearchQuery: "",
      selectedClub: null,
      isPlayerModalVisible: false,
      isDeletePlayerConfirmVisible: false,
      editingPlayer: null,
      playerForm: {
        last_name: "",
        first_name: "",
        current_club_name: "",
        dominant_hand: "",
        hand_notes: "",
      },
      playerFields: [
        { key: "name", label: "名前", sortable: true },
        { key: "current_club", label: "かるた会", sortable: true },
        { key: "latest_comment", label: "最新コメント", sortable: false },
        { key: "actions", label: "操作", sortable: false },
      ],
      deletePlayerId: null,
      deletePlayerPassword: "",
      sortBy: "name",
      sortDesc: false,
      currentPage: 1,
      perPage: 10,
      isAdmin: false,
      searchTimer: null,
      expandedClubs: {},
    };
  },
  computed: {
    filteredPlayers() {
      let filtered = this.players;

      if (this.searchQuery) {
        const query = this.searchQuery.toLowerCase();
        filtered = filtered.filter((player) => {
          return (
            player.last_name.toLowerCase().includes(query) ||
            player.first_name.toLowerCase().includes(query) ||
            player.current_club.name.toLowerCase().includes(query)
          );
        });
      }

      if (this.selectedClub) {
        filtered = filtered.filter((player) => player.current_club.id === this.selectedClub);
      }

      return filtered;
    },
    clubFilterOptions() {
      return this.clubOptions.map((club) => ({
        value: club.value,
        text: club.text,
      }));
    },
    paginatedPlayers() {
      const start = (this.currentPage - 1) * this.perPage;
      const end = start + this.perPage;
      return this.filteredPlayers.slice(start, end);
    },
    sortedClubs() {
      return this.clubs.slice().sort((a, b) => b.player_count - a.player_count);
    },
  },
  async created() {
    this.fetchPlayers();
    this.fetchClubs();
    this.fetchLatestComments();
    this.fetchClubsWithPlayers();

    const auth = getAuth(firebaseApp);
    if (config.adminUsersEmail.includes(auth.currentUser.email)) {
      this.isAdmin = true;
    }
  },
  methods: {
    // Data fetching methods
    async fetchPlayers() {
      try {
        let { data: players, error } = await supabase
          .from("players")
          .select(
            `
            *,
            current_club:clubs (id, name),
            comment_count:comments(count),
            latest_comment:comments (
              id,
              content,
              poster_name,
              created_at
            )
          `
          )
          .order("updated_at", { ascending: false })
          .order("created_at", { foreignTable: "latest_comment", ascending: false })
          .limit(1, { foreignTable: "latest_comment" });

        if (error) throw error;

        this.players = players.map((player) => ({
          ...player,
          latest_comment: player.latest_comment[0] || null,
          comment_count: player.comment_count[0].count,
        }));
      } catch (error) {
        console.error("Error fetching players:", error.message);
      }
    },
    async fetchClubs() {
      try {
        let { data: clubs, error } = await supabase.from("clubs").select("id, name");
        if (error) throw error;
        this.clubOptions = clubs.map((club) => ({ value: club.id, text: club.name }));
      } catch (error) {
        console.error("Error fetching clubs:", error.message);
      }
    },
    async fetchLatestComments() {
      try {
        let { data: comments, error } = await supabase
          .from("comments")
          .select(
            `
            *,
            player:players (id, last_name, first_name)
          `
          )
          .ilike("content", `%${this.commentSearchQuery}%`)
          .order("created_at", { ascending: false })
          .range(0, 50);
        if (error) throw error;

        this.latestComments = comments;
      } catch (error) {
        console.error("Error fetching latest comments:", error.message);
      }
    },
    async fetchClubsWithPlayers() {
      try {
        let { data: clubs, error } = await supabase.from("clubs").select(`
          id,
          name,
          players (
            id,
            last_name,
            first_name,
            comments (count)
          )
        `);

        if (error) throw error;

        this.clubs = clubs.map((club) => ({
          ...club,
          player_count: club.players.length,
          players: club.players.map((player) => ({
            ...player,
            comment_count: player.comments[0].count,
          })),
        }));
      } catch (error) {
        console.error("Error fetching clubs with players:", error.message);
      }
    },

    // Player management methods
    showAddPlayerModal() {
      this.editingPlayer = null;
      this.resetPlayerForm();
      this.isPlayerModalVisible = true;
    },
    editPlayer(player) {
      this.editingPlayer = player;
      this.playerForm = {
        last_name: player.last_name,
        first_name: player.first_name,
        current_club_name: player.current_club.name,
      };
      this.isPlayerModalVisible = true;
    },
    async savePlayer() {
      try {
        let currentClubId = this.getClubIdByName(this.playerForm.current_club_name);

        if (!currentClubId) {
          const { data, error } = await supabase
            .from("clubs")
            .insert([{ name: this.playerForm.current_club_name }])
            .select();

          if (error) throw error;
          currentClubId = data[0].id;
          this.fetchClubs();
        }

        if (this.editingPlayer) {
          let { error } = await supabase
            .from("players")
            .update({
              last_name: this.playerForm.last_name,
              first_name: this.playerForm.first_name,
              dominant_hand: this.playerForm.dominant_hand,
              hand_notes: this.playerForm.hand_notes,
              current_club_id: currentClubId,
            })
            .eq("id", this.editingPlayer.id);
          if (error) throw error;
        } else {
          let { error } = await supabase.from("players").insert([
            {
              last_name: this.playerForm.last_name,
              first_name: this.playerForm.first_name,
              dominant_hand: this.playerForm.dominant_hand,
              hand_notes: this.playerForm.hand_notes,
              current_club_id: currentClubId,
            },
          ]);
          if (error) throw error;
        }
      } catch (error) {
        console.error("Error saving player:", error.message);
      } finally {
        this.isPlayerModalVisible = false;

        this.fetchPlayers();
        this.fetchClubs();
        this.fetchLatestComments();
        this.fetchClubsWithPlayers();
      }
    },
    confirmDeletePlayer(playerId) {
      this.deletePlayerId = playerId;
      this.isDeletePlayerConfirmVisible = true;
    },
    async deletePlayer() {
      try {
        if (this.deletePlayerPassword === "your-password-here") {
          let { error } = await supabase.from("players").delete().eq("id", this.deletePlayerId);
          if (error) throw error;
          this.fetchPlayers();
          this.isDeletePlayerConfirmVisible = false;
        } else {
          alert("パスワードが間違っています。");
        }
      } catch (error) {
        console.error("Error deleting player:", error.message);
      }
    },

    // Utility methods
    getClubIdByName(name) {
      const club = this.clubOptions.find((club) => club.text === name);
      return club ? club.value : null;
    },
    resetPlayerForm() {
      this.playerForm = { last_name: "", first_name: "", current_club_name: "" };
      this.similarPlayers = [];
    },
    resetDeletePlayerConfirm() {
      this.deletePlayerId = null;
      this.deletePlayerPassword = "";
    },
    viewPlayerDetail(playerId) {
      this.$router.push({ name: "PlayerDetail", params: { id: playerId } });
    },
    filterPlayers() {
      this.currentPage = 1;
    },
    truncateComment(comment) {
      return comment.length > 50 ? comment.substring(0, 50) + "..." : comment;
    },
    formatDate(date) {
      return dayjs(date).format("YYYY/MM/DD HH:mm");
    },

    // Search and sort methods
    searchPlayers() {
      clearTimeout(this.searchTimer);
      this.searchTimer = setTimeout(async () => {
        const lastName = this.playerForm.last_name.trim();
        const firstName = this.playerForm.first_name.trim();

        if (lastName.length > 0 || firstName.length > 0) {
          try {
            let query = supabase
              .from("players")
              .select("*, current_club:clubs(name)")
              .order("last_name", { ascending: true })
              .order("first_name", { ascending: true })
              .limit(10);

            if (lastName.length > 0 && firstName.length > 0) {
              query = query.or(`and(last_name.ilike.%${lastName}%,first_name.ilike.%${firstName}%)`);
            } else if (lastName.length > 0) {
              query = query.ilike("last_name", `%${lastName}%`);
            } else if (firstName.length > 0) {
              query = query.ilike("first_name", `%${firstName}%`);
            }

            let { data: players, error } = await query;

            if (error) throw error;

            this.similarPlayers = players.filter(
              (player) => player.id !== (this.editingPlayer ? this.editingPlayer.id : null)
            );
          } catch (error) {
            console.error("Error searching for players:", error.message);
            this.similarPlayers = [];
          }
        } else {
          this.similarPlayers = [];
        }
      }, 300);
    },
    getSortedPlayers(club) {
      return [...club.players].sort((a, b) => b.comment_count - a.comment_count);
    },

    // UI interaction methods
    toggleClubDetails(clubId) {
      this.$set(this.expandedClubs, clubId, !this.expandedClubs[clubId]);
    },
  },
};
</script>

<style scoped>
.club-group {
  margin-bottom: 1rem;
}

.club-item {
  background-color: #f8f9fa;
  margin-left: 1rem;
  margin-right: 1rem;
}

.player-list {
  padding-left: 1rem;
  padding-right: 1rem;
  margin-left: 1rem;
  margin-right: 1rem;
}

.player-card {
  box-shadow: none;
  border-left: 4px solid #6c757d;
  border-right: 4px solid #6c757d;
  border-bottom: 4px solid #6c757d;
  border-radius: 0 0 0.25rem 0.25rem;
}

.player-card .card-body {
  padding-top: 0;
}

.player-item {
  background-color: white;
  border-left: none;
  border-right: none;
  padding-left: 0.5rem;
  padding-right: 0.5rem;
}

.player-item:first-child {
  border-top: none;
}

.player-item:last-child {
  border-bottom: none;
}

.list-group-item {
  cursor: pointer;
}

.list-group-item:hover {
  background-color: #e9ecef;
}

.list-group > div:not(:last-child) {
  margin-bottom: 0;
}

.table th {
  background-color: #f1f3f5;
}

.pagination {
  justify-content: center;
}

@media (max-width: 768px) {
  .btn-group {
    display: flex;
    flex-direction: column;
  }

  .btn-group > .btn {
    margin-bottom: 0.5rem;
    border-radius: 0.25rem !important;
  }
}

.existing-players-description {
  font-size: 0.9rem;
  color: #6c757d;
  margin-bottom: 15px;
}

.existing-players-list {
  flex-grow: 1;
  overflow-y: auto;
}
</style>
